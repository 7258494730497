<div
  [matTooltip]="description"
  [gaClickListener]="{
    action: 'DescriptionClick',
    category: 'Projects',
    label: 'CartItemList',
  }"
  class="relative-position full-width cut-text description-container"
  data-test="description-column"
>
  {{ description }}
</div>
