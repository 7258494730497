import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  ContractItem,
  Currency,
  CustomArticleItem,
  MccItem,
  ProductItem,
  ProductItemProductType,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { SlCartItemGroupContainer } from '@sales-libs/project/ui';
import {
  ContractName,
  SlCartItem,
  SlSharedQuantityData,
} from '@sales-libs/shared/util';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';
import { WarrantyExtensionUtils } from '../../utils/warranty-extension.utils';

const moduleState = createFeatureSelector<ProjectModuleState>('projectModule');

export const cartState = createSelector(moduleState, (state) => state?.cart);

export namespace CartSelectors {
  export const id = createSelector(cartState, (state) => state.id);
  export const current = createSelector(cartState, (state) => state?.current);
  export const currency = createSelector(
    cartState,
    (state) => state?.current?.currency_settings,
  );
  export const currencyRatio = createSelector(
    cartState,
    (state) => state?.current?.currency_settings?.ratio ?? 1,
  );
  export const version = createSelector(
    current,
    (_cart) => _cart?.version || '',
  );

  // cart items
  export const productItems = createSelector(
    current,
    (_cart) =>
      _cart?.product_items &&
      _cart.product_items &&
      _cart.product_items.filter((item) => !item.is_optional),
  );
  export const productItemsAll = createSelector(
    current,
    (_cart) => _cart?.product_items,
  );
  export const mainProduct = createSelector(
    productItems,
    (items) =>
      items?.find((x) => x.product_type === ProductItemProductType.Product) ||
      null,
  );

  export const allItems = createSelector(
    current,
    mainProduct,
    currency,
    (_cart, _craneCartItem, _currency) => [
      ...(_cart?.solution_items ?? ([] as ProductItem[])),
      ...(_cart?.product_items ?? ([] as ProductItem[])).filter(
        (item) => item.product_type === ProductItemProductType.Product,
      ),
      ...(_cart?.product_items ?? ([] as ProductItem[])).filter(
        (item) =>
          item.product_type === ProductItemProductType.Accessory &&
          item.is_configurable,
      ),
      ...(_cart?.product_items ?? ([] as ProductItem[])).filter(
        (item) =>
          item.product_type === ProductItemProductType.Accessory &&
          !item.is_configurable,
      ),
      // double clone to not mutate the original array
      ...[...(_cart?.custom_article_items ?? ([] as CustomArticleItem[]))].sort(
        (a, b) => a.sort_key - b.sort_key,
      ),
      ...(_cart?.tuning_center_items ?? ([] as TuningCenterItem[])),
      ...(_cart?.sales_option_items ?? ([] as SalesOptionCartItem[])),
      ...(WarrantyExtensionUtils.getItemsWithPrices(
        _cart?.warranty_extension_items,
        _craneCartItem,
        _currency as Currency,
      ) ?? ([] as WarrantyExtensionItem[])),
      ...(_cart?.contract_items ?? ([] as ContractItem[])),
      ...(_cart?.mcc_items ?? ([] as MccItem[])),
    ],
  );

  export const allPalfingerItems = createSelector(
    current,
    mainProduct,
    currency,
    (_cart, _craneCartItem, _currency) => [
      ...(_cart?.product_items ?? ([] as ProductItem[])),
      ...(_cart?.tuning_center_items ?? ([] as TuningCenterItem[])),
      ...(_cart?.mcc_items ?? ([] as MccItem[])),
      ...(_cart?.sales_option_items ?? ([] as SalesOptionCartItem[])),
      ...(WarrantyExtensionUtils.getItemsWithPrices(
        _cart?.warranty_extension_items,
        _craneCartItem,
        _currency as Currency,
      ) ?? ([] as WarrantyExtensionItem[])),
      ...(_cart?.contract_items ?? ([] as ContractItem[])),
    ],
  );

  export const cartItems = createSelector(allItems, (_allItems) =>
    _allItems.filter((item) => !item.is_optional),
  );

  export const optionalCartItemsGrouped = createSelector(
    current,
    allItems,
    (_cart, _allItems) => {
      if (_cart) {
        let itemContainers: SlCartItemGroupContainer[] = [];
        itemContainers = _allItems
          .filter(
            (item) =>
              item.is_optional &&
              (!item.cart_item_group_id ||
                (item.cart_item_group_id &&
                  !_cart.optional_cart_item_groups?.find(
                    (group) => group.id === item.cart_item_group_id,
                  ))),
          )
          .map(
            // we need map ungrouped items also into a container
            // so we can use the same ui component for both grouped and ungrouped options
            (item) => ({
              items: [item],
              sort_key: item.sort_key_optional,
            }),
          );

        let groupContainers: SlCartItemGroupContainer[] = [];
        groupContainers =
          _cart.optional_cart_item_groups?.map(
            (group) =>
              ({
                group: group,
                items: _allItems
                  .filter((items) => items.cart_item_group_id === group.id)
                  .sort((a, b) => a.sort_key_optional - b.sort_key_optional),
                sort_key: group.sort_key_ui,
              }) as SlCartItemGroupContainer,
          ) || [];

        return [...itemContainers, ...groupContainers].sort(
          (a, b) => a.sort_key - b.sort_key,
        );
      }
      return undefined;
    },
  );

  const getQuantityDataFromCartItems = (
    items: SlCartItem[] | null | undefined,
  ): SlSharedQuantityData[] =>
    items?.map((item) => {
      const originalName = (item as ProductItem).original_name;
      return {
        name: originalName ?? (item.name as string),
        material_id:
          (item as WarrantyExtensionItem).material ??
          (item as ProductItem).material_id ??
          undefined,
        quantity: item.quantity,
        optional: item.is_optional,
        custom_article_id: '' + (item as CustomArticleItem).custom_article_id,
      };
    }) || ([] as SlSharedQuantityData[]);

  export const quantityData = createSelector(current, (_cart) =>
    _cart
      ? [
          ...getQuantityDataFromCartItems(_cart.product_items),
          ...getQuantityDataFromCartItems(_cart.contract_items),
          ...getQuantityDataFromCartItems(_cart.custom_article_items),
          ...getQuantityDataFromCartItems(_cart.sales_option_items),
          ...getQuantityDataFromCartItems(_cart.mcc_items),
          ...getQuantityDataFromCartItems(_cart.tuning_center_items),
          ...getQuantityDataFromCartItems(_cart.warranty_extension_items),
        ]
      : ([] as SlSharedQuantityData[]),
  );

  export const quantityDataWithoutOptional = createSelector(
    quantityData,
    (data) => data.filter((x) => !x.optional),
  );

  export const one = (itemId: string) =>
    createSelector(cartItems, (items) => items?.find((x) => x.id === itemId));

  export const oneOption = (optionName: string) =>
    createSelector(mainProduct, (craneItem) =>
      craneItem?.options?.find((x) => x.name === optionName),
    );

  export const accessoryCartItem = createSelector(
    productItems,
    (items) =>
      items?.find((x) => x.product_type === ProductItemProductType.Accessory) ??
      null,
  );

  export const serviceContractItem = createSelector(
    current,
    (cart) =>
      cart?.contract_items?.find((x) => x.name === ContractName.SERVICE) ??
      null,
  );

  export const connectedContractItem = createSelector(
    current,
    (cart) =>
      cart?.contract_items?.find((x) => x.name === ContractName.CONNECTED) ??
      null,
  );

  export const firstItem = createSelector(
    allItems,
    (items) => (items && items.length > 0 && items[0]) || null,
  );

  export const customArticles = createSelector(
    current,
    (_cart) => _cart?.custom_article_items,
  );

  export const solutionItems = createSelector(
    current,
    (_cart) => _cart?.solution_items,
  );

  export const equipments = createSelector(current, (_cart) =>
    _cart?.product_items?.filter(
      (x) => x.product_type === ProductItemProductType.Accessory,
    ),
  );

  export const cartItemsAmount = createSelector(cartItems, (items) =>
    items?.map((x) => x.quantity).reduce((prev, next) => prev + next, 0),
  );

  export const stepperState = createSelector(
    cartState,
    (state) => state.stepper,
  );

  export const selectedStep = createSelector(
    stepperState,
    (state) => state.selectedIndex,
  );
  export const loading = createSelector(cartState, (state) => state.loading);

  export const isPricingVisible = createSelector(
    cartState,
    (state) => state.isPricingVisible,
  );

  // load diagram
  const loadDiagramState = createSelector(
    cartState,
    (state) => state.loadDiagram,
  );
  export const loadDiagramLoadingStatus = createSelector(
    loadDiagramState,
    (state) => state.loadingStatus,
  );
  export const loadDiagramUrl = createSelector(
    loadDiagramState,
    (state) => state.url,
  );

  // stabilizer drawing
  const stabilierDrawingState = createSelector(
    cartState,
    (state) => state.stabilizerDrawing,
  );
  export const stabilizerDrawingLoadingStatus = createSelector(
    stabilierDrawingState,
    (state) => state.loadingStatus,
  );
  export const stabilizerDrawingUrl = createSelector(
    stabilierDrawingState,
    (state) => state.url,
  );
  // documents
  export const documents = createSelector(
    cartState,
    (state) => state.documents,
  );

  export const isCompareLoading = createSelector(
    cartState,
    (state) => state.isCompareLoading,
  );
  export const isCartDeleted = createSelector(
    cartState,
    (state) => state.isCartDeleted,
  );

  export const hasLoadingError = createSelector(
    cartState,
    (state) => state.hasLoadingError,
  );

  export const language = createSelector(
    cartState,
    (state) => state.current?.language,
  );

  // CART ITEMS
  export const contractItems = createSelector(current, (_cart) =>
    _cart?.contract_items?.filter((item) => !item.is_optional),
  );
  export const customArticleItems = createSelector(current, (_cart) =>
    _cart?.custom_article_items?.filter((item) => !item.is_optional),
  );

  export const salesOptionItems = createSelector(
    current,
    (_cart) => _cart?.sales_option_items,
  );
  export const tuningCenterItems = createSelector(
    current,
    (_cart) => _cart?.tuning_center_items,
  );

  export const mccItems = createSelector(current, (_cart) => _cart?.mcc_items);

  export const warrantyExtensionItems = createSelector(
    current,
    mainProduct,
    currency,
    (_cart, _craneCartItem, _currency) =>
      WarrantyExtensionUtils.getItemsWithPrices(
        _cart?.warranty_extension_items?.filter((item) => !item.is_optional),
        _craneCartItem,
        _currency as Currency,
      ),
  );

  export const maxSortKeyOptional = createSelector(
    optionalCartItemsGrouped,
    (groups) =>
      Math.max(
        ...(groups?.reduce(
          (acc, group) => [
            ...acc,
            group.sort_key,
            ...group.items.map((item) => item.sort_key_optional),
          ],
          [0],
        ) ?? [0]),
      ),
  );
}
