import { Injectable, inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { CartItemsService } from '@sales-libs/project/data-access';
import { SnackbarActions } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { catchError, map, mergeMap, switchMap, withLatestFrom } from 'rxjs';
import { CartActions } from '../cart.actions';
import { CartSelectors } from '../cart.selectors';

@Injectable()
export class MccItemEffects {
  private readonly _actions: Actions = inject(Actions);
  private readonly _store: Store = inject(Store);

  get$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.GetMccItem),
      map((action) => action.id),
      withLatestFrom(
        this._store.select(CartSelectors.current).pipe(filterTruthy()),
      ),
      switchMap(([id, cart]) =>
        this._cartItemsService.getMccItemAsync(cart.id, id).pipe(
          switchMap((data) => [
            CartActions.GetMccItemSuccess({
              item: data,
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.mcc_not_loaded',
              ),
            }),
            CartActions.GetMccItemError(),
          ]),
        ),
      ),
    ),
  );

  add$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.AddMccItem),
      map((action) => action.item),
      mergeMap((item) =>
        this._cartItemsService.addMccItemAsync(item).pipe(
          mergeMap((data) => [
            CartActions.AddMccItemSuccess({
              item: data,
            }),
            SnackbarActions.ShowSuccess({
              message: this._translateService.instant(
                'configuration.added_to_cart',
                { item: data.name },
              ),
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.mcc_not_added',
              ),
            }),
            CartActions.AddMccItemError(),
          ]),
        ),
      ),
    ),
  );

  update$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateMccItem),
      map((action) => action.update),
      mergeMap((update) =>
        this._cartItemsService.updateMccItemAsync(update).pipe(
          map(() =>
            CartActions.UpdateMccItemSuccess({
              update,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.mcc_not_updated',
              ),
            }),
            CartActions.UpdateMccItemError(),
          ]),
        ),
      ),
    ),
  );

  delete$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.DeleteMccItem),
      mergeMap((payload) =>
        this._cartItemsService
          .deleteMccItemAsync(payload.cartId, payload.itemId)
          .pipe(
            map(() =>
              CartActions.DeleteMccItemSuccess({
                id: payload.itemId,
              }),
            ),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.cart.mcc_not_removed',
                ),
              }),
              CartActions.DeleteMccItemError(),
            ]),
          ),
      ),
    ),
  );

  constructor(
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
