import { CartItemsService } from '@sales-libs/project/data-access';
import {
  BehaviorSubject,
  catchError,
  map,
  Observable,
  ReplaySubject,
  switchMap,
} from 'rxjs';
import { SlProjectDataCreationProductItem } from '../types';
import { SlProjectCartItemCreationRow } from './cart-item-creation-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectProductItemCreationRow extends SlProjectCartItemCreationRow {
  constructor(
    private _productItem: SlProjectDataCreationProductItem,
    private _cartItemsService: CartItemsService,
    private _completion$: ReplaySubject<void>,
    private _previousCompletion$?: Observable<void>,
  ) {
    super(_productItem.name);
  }

  getCompletion() {
    return this._completion$.asObservable();
  }

  protected _creationFunction = () =>
    (
      this._previousCompletion$ ??
      new BehaviorSubject<void>(undefined).asObservable()
    ).pipe(
      switchMap(() =>
        this._cartItemsService
          .addProductItemAsync({
            ...this._productItem,
            cart_id: this.cartId,
            cart_item_group_id: this.cartItemGroupId,
          })
          .pipe(
            map((data) => {
              this._completion$.next();
              return {
                result: {
                  data,
                  calculationItemReferenceId:
                    this._productItem.calculationItemReferenceId,
                },
                state: SlProjectDataCreationRowState.Success,
              };
            }),
            catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
          ),
      ),
    );
}
