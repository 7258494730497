import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Optional,
  Output,
} from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import {
  Calculation,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';
import { MAX_ROUNDING_DIGITS } from '@sales-libs/shared/util';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-sales-price-column',
  templateUrl: './sales-price-column.component.html',
  styleUrls: ['./sales-price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectSalesSlProjectPriceColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() currencySymbol: string;
  @Input() currencyRatio: number;
  @Input() currencyRoundingDigits: number;
  @Input() isAlternativeView = false;
  @Input() set calculation(value: Calculation) {
    this._calculation = value;
    this.subTotal = CalculationUtils.getItemsTotal(
      value.items,
      this.perspective,
      this.isAlternativeView,
      true,
      false,
      this.currencyRatio,
      this.currencyRoundingDigits,
    );
  }
  get calculation() {
    return this._calculation;
  }
  @Output() calculationChange = new EventEmitter<Calculation>();

  MAX_ROUNDING_DIGITS = MAX_ROUNDING_DIGITS;
  itemTypes = CalculationItemItemType;
  parentForm: FormGroup;
  subTotal: number;
  private _calculation: Calculation;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }

  onInit() {
    this.parentForm = this.formDirective.form;
  }
}
