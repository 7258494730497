import {
  CartItemsService,
  ProductItemInput,
} from '@sales-libs/project/data-access';
import { ReplaySubject } from 'rxjs';
import { SlProjectProductItemCreationRow } from '../rows/product-item-creation-row';

export class SlProjectProductItemCreationRowFactory {
  constructor(
    private _cartItemsService: CartItemsService,
    private rowClass = SlProjectProductItemCreationRow,
  ) {}

  create(items: ProductItemInput[]) {
    // there is a race condition in backend, that can cause faulty model name in project overview
    // to prevent this we add the product items after each other, by letting them wait for the previous completion
    const productItemRows: SlProjectProductItemCreationRow[] = [];
    items.forEach((item, index) => {
      const previousCompletion$ =
        index > 0 ? productItemRows[index - 1].getCompletion() : undefined;
      productItemRows.push(
        new this.rowClass(
          item,
          this._cartItemsService,
          new ReplaySubject(1),
          previousCompletion$,
        ),
      );
    });

    return productItemRows;
  }
}
