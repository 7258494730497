import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  Optional,
} from '@angular/core';
import { FormGroupDirective } from '@angular/forms';
import { MatTable } from '@angular/material/table';
import { DsColumnComponent } from '@design-system/cdk/column';
import {
  Calculation,
  CalculationItemItemType,
} from '@sales-libs/project/data-access';
import { MAX_ROUNDING_DIGITS } from '@sales-libs/shared/util';
import { CalculationUtils } from '../../shared/calculation-utils';

@Component({
  selector: 'sl-project-purchase-price-column',
  templateUrl: './purchase-price-column.component.html',
  styleUrls: ['./purchase-price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectPurchasePriceColumnComponent extends DsColumnComponent {
  @Input() perspective: string;
  @Input() isAlternativeView = false;
  @Input() currencyRatio: number;
  @Input() currencyRoundingDigits: number;
  @Input() set calculation(value: Calculation) {
    this.subTotal = CalculationUtils.getItemsTotal(
      value.items,
      this.perspective,
      this.isAlternativeView,
      false,
      false,
      this.currencyRatio,
      this.currencyRoundingDigits,
    );
  }

  MAX_ROUNDING_DIGITS = MAX_ROUNDING_DIGITS;
  itemTypes = CalculationItemItemType;
  subTotal: number;

  constructor(
    @Optional() public table: MatTable<any>,
    @Optional() protected formDirective: FormGroupDirective,
    protected cdRef: ChangeDetectorRef,
  ) {
    super(table, formDirective, cdRef);
  }
}
