/* eslint-disable max-lines */
/* eslint-disable sonarjs/no-duplicate-string */
import { CommonModule, DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { ActivatedRoute } from '@angular/router';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsDrawerModule } from '@design-system/components/drawer';
import { DsPageModule } from '@design-system/feature/page';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { OfferJsReportInput } from '@sales-libs/project/data-access';
import {
  SlProjectDigitalOfferBenefitsComponent,
  SlProjectDigitalOfferInfoComponent,
  SlProjectDigitalOfferPricesComponent,
  SlProjectDigitalOfferSpecificationsComponent,
  SlProjectDigitalOfferTechnicalDataComponent,
  SlProjectDigitalOfferTermsAndConditionsComponent,
} from '@sales-libs/project/ui';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { filterTruthy } from '@shared-lib/rxjs';
import { take } from 'rxjs';
import { DigitalOfferActions } from '../store';

@Component({
  selector: 'sl-project-digital-offer-page',
  imports: [
    CommonModule,
    DsPageModule,
    DsSpacingModule,
    DsDrawerModule,
    MatCardModule,
    MatButtonModule,
    MatMenuModule,
    TranslateModule,
    FlexLayoutModule,
    GoogleAnalytics4DirectiveModule,
    SlProjectDigitalOfferSpecificationsComponent,
    SlProjectDigitalOfferBenefitsComponent,
    SlProjectDigitalOfferTermsAndConditionsComponent,
    SlProjectDigitalOfferTechnicalDataComponent,
    SlProjectDigitalOfferInfoComponent,
    SlProjectDigitalOfferPricesComponent,
  ],
  templateUrl: './digital-offer-page.component.html',
  styleUrls: ['./digital-offer-page.component.scss'],
})
export class SlProjectDigitalOfferPageComponent implements OnInit {
  projectCode?: string;
  cartVersion?: number;
  offerNumber: string;
  offerData: OfferJsReportInput | undefined;

  constructor(
    private _store: Store,
    private _actions: Actions,
    private _route: ActivatedRoute,
    private _translateService: TranslateService,
    @Inject(DOCUMENT) private _document: Document,
  ) {
    // language can be set by translate module after we loaded the offer data
    // in this case we set the translation again, with the now current language
    this._translateService.onLangChange
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this._setTranslation();
      });
  }

  ngOnInit(): void {
    this.getOfferNumberFromUrl();
    this.getDigitalOfferDataByOfferNumber();
  }

  getOfferNumberFromUrl(): void {
    this._route?.params.pipe(take(1)).subscribe((event) => {
      this.offerNumber = event.offerId;
    });
  }

  getDigitalOfferDataByOfferNumber(): void {
    this._store.dispatch(
      DigitalOfferActions.getDigitalOfferDataByOfferNumber({
        digitalOfferId: this.offerNumber,
      }),
    );
    this._actions
      .pipe(
        ofType(DigitalOfferActions.getDigitalOfferDataByOfferNumberSuccess),
        filterTruthy(),
        take(1),
      )
      .subscribe((payload) => {
        this.offerData = payload.digitalOfferData;
        this._setTranslation();
      });
  }

  scrollToElement(id: string): void {
    const element = this._document.getElementById(id) as any;
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  private _setTranslation(): void {
    if (this.offerData?.translations) {
      this._translateService.setTranslation(
        this._translateService.currentLang,
        this.offerData.translations,
        true,
      );
    }
  }
}
