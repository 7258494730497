<ng-container gaLabelGroup="CartItemList" gaCategoryGroup="Project">
  <button
    mat-button
    *ngIf="isSelectOnly"
    (click)="selectClick.emit(cartItem)"
    data-test="select"
    gaClickListener="SelectClick"
  >
    {{ 'general.select' | translate }}
  </button>
  <ng-container *ngIf="!isSelectOnly">
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      data-test="secondary-actions"
      gaClickListener="MoreClick"
    >
      <mat-icon>more_vert</mat-icon>
    </button>
    <mat-menu #menu="matMenu" data-test="menu">
      <div gaCategoryGroup="Project" gaLabelGroup="ActionsMatMenu">
        <a
          mat-menu-item
          *ngIf="cartItem | isContractItem"
          target="_blank"
          [href]="
            sccDomain +
            (itemAsContractItem.name === contractName.SERVICE
              ? '/service/'
              : '/connected/') +
            itemAsContractItem.service_contract?.contract_id
          "
          data-test="contract-detail-button"
          gaClickListener="ServiceContractDetailsClick"
        >
          <mat-icon>info</mat-icon>
          {{ 'general.details' | translate }}
        </a>
        <button
          mat-menu-item
          *ngIf="
            itemAsProductItem.is_configurable === true ||
            (cartItem | isSolutionItem)
          "
          (click)="showConfigurationClick.emit(cartItem)"
          data-test="configuration"
          gaClickListener="ConfigurationClick"
        >
          <mat-icon>info</mat-icon>
          <span>{{ 'orders.show_configuration' | translate }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="
            (itemAsProductItem.is_configurable ||
              (cartItem | isSolutionItem)) &&
            !isReadOnly
          "
          (click)="configureClick.emit(cartItem)"
          data-test="configure"
          gaClickListener="ConfigureClick"
          [disabled]="isReadOnly"
        >
          <mat-icon>build</mat-icon>
          <span>{{ 'common.configure' | translate }}</span>
        </button>
        <button
          mat-menu-item
          *ngIf="
            (cartItem | isTuningCenterItem) ||
            (cartItem | isCustomArticleItem) ||
            (cartItem | isMccItem)
          "
          (click)="editClick.emit(cartItem)"
          data-test="edit"
          gaClickListener="EditClick"
          [disabled]="isReadOnly"
        >
          <mat-icon>edit</mat-icon>
          <span>{{ 'common.edit' | translate }}</span>
        </button>
        <div
          *ngIf="
            !(cartItem | isSalesOptionItem) &&
            !(
              (cartItem | isProductItem) &&
              itemAsProductItem.product_type === productTypes.Product
            ) &&
            !(cartItem | isSolutionItem)
          "
          [matTooltipPosition]="'left'"
          [matTooltip]="
            itemAsProductItem.is_configurable && !itemAsProductItem.is_valid
              ? ('debug_errors.change_to_optional_hint' | translate)
              : null
          "
        >
          <button
            mat-menu-item
            (click)="toOptionalClick.emit(cartItem)"
            data-test="optional"
            gaClickListener="SetOptionalClick"
            [disabled]="isReadOnly || itemAsProductItem.is_valid === false"
          >
            <mat-icon>watch_later</mat-icon>
            <span>{{ 'project.change_to_optional' | translate }}</span>
          </button>
        </div>
        <!-- todo: check || !cartItem.actions?.includes(actions.Delete) -->
        <button
          mat-menu-item
          [disabled]="isReadOnly"
          (click)="deleteClick.emit(cartItem)"
          data-test="remove"
          gaClickListener="DeleteClick"
        >
          <mat-icon>remove_shopping_cart</mat-icon>
          <span>{{ 'project.remove_from_cart' | translate }}</span>
        </button>
      </div>
    </mat-menu>
  </ng-container>
</ng-container>
