<ng-container matColumnDef="reason">
  <th
    mat-header-cell
    mat-sort-header
    *matHeaderCellDef
    id="reason"
    data-test="lost-order-reason-header"
  >
    {{ 'table_columns.reason' | translate }}
  </th>
  <td
    mat-cell
    *matCellDef="let lostOrder"
    [gaClickListener]="{
      category: 'LostOrderOverview',
      label: 'LostOrderList',
      action: 'ReasonClick',
    }"
    data-test="lost-order-reason"
  >
    {{
      { id: lostOrder.reason_id, name: lostOrder.reason }
        | slProjectReasonTranslation
    }}
  </td>
</ng-container>
