/* eslint-disable max-lines */
import { coerceNumberProperty } from '@angular/cdk/coercion';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ROUTER_NAVIGATED, RouterNavigationAction } from '@ngrx/router-store';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
  CartItemsService,
  CartsService,
} from '@sales-libs/project/data-access';
import { CurrencyActions, SnackbarActions } from '@sales-libs/shared/util';
import { filterTruthy } from '@shared-lib/rxjs';
import { interval } from 'rxjs';
import {
  catchError,
  concatMap,
  distinctUntilChanged,
  filter,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs/operators';
import { CalculationActions } from '../../calculation/store';
import { ProjectActions } from '../projects';
import { ProjectSelectors } from '../projects/project.selectors';
import { CartActions } from './cart.actions';
import { StepperMapping } from './cart.reducer';
import { CartSelectors } from './cart.selectors';

function getStepPath(step: StepperMapping): string | null {
  switch (step) {
    case StepperMapping.Model:
      return 'models';
    case StepperMapping.Options:
      return 'options';
    case StepperMapping.Pec:
      return 'equipment';
    case StepperMapping.CustomArticles:
      return 'custom-articles';
    case StepperMapping.Result:
      return null;
    default:
      return null;
  }
}

@Injectable()
export class CartEffects {
  private _previousRoute = '';
  private _actions: Actions = inject(Actions);
  private _store: Store = inject(Store);

  cartId$ = createEffect(() =>
    this._actions.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATED),
      map((action) => action.payload),
      map((payload) => payload.routerState['params']),
      map((params) => coerceNumberProperty(params['cartId'], null)),
      distinctUntilChanged(),
      map((paramId) => CartActions.SetCartId({ payload: paramId })),
    ),
  );

  calculationToCartNavigation$ = createEffect(() =>
    this._actions.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATED),
      map((action) => action.payload),
      filter((payload) => {
        const fromCalcAway =
          this._previousRoute.indexOf('/calculation') !== -1 &&
          payload.event.url.indexOf('/calculation') === -1;
        this._previousRoute = payload.event.url;
        return fromCalcAway;
      }),
      map((payload) => payload.routerState['params']),
      map((params) => coerceNumberProperty(params['cartId'], null)),
      filter((cartId) => cartId != null),
      map((cartId) => CartActions.LoadCart({ payload: cartId || undefined })),
    ),
  );

  revokeObjectUrl$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(CartActions.RevokeObjectUrl),
        map((action) => URL.revokeObjectURL(action.url)),
      ),
    { dispatch: false },
  );

  triggerLoadCart$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.SetCartId),
      map((action) => action.payload),
      filter((payload) => payload !== null),
      map((payload) => CartActions.LoadCart({ payload: payload || undefined })),
    ),
  );

  resetOfferUrl$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.LoadCart),
      map(() => CalculationActions.ResetOfferUrl()),
    ),
  );

  getCart$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.LoadCart),
      map((action) => action.payload),
      withLatestFrom(this._store.select(CartSelectors.id)),
      map(([cartId, storeId]) => cartId ?? storeId),
      filterTruthy(),
      switchMap((cartId) =>
        this._cartsService.getCartById(cartId).pipe(
          mergeMap((data) => [
            CartActions.LoadCartSuccess({ payload: data }),
            CurrencyActions.SetContextSpecificCurrency({
              payload: data.currency_settings,
            }),
          ]),
          catchError((response) => [
            CartActions.LoadCartError({
              isCartDeleted:
                response.status === 404 && !!response.error?.log_id,
            }),
          ]),
        ),
      ),
    ),
  );

  updateCart$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateCart),
      mergeMap((action) =>
        this._cartsService.updateCart(action.update).pipe(
          mergeMap(() => [
            CartActions.UpdateCartSuccess({ payload: action.update }),
            ...(action.isActiveUpdate
              ? [ProjectActions.SetCartActive({ cartId: action.update.id })]
              : []),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.not_updated',
              ),
            }),
            CartActions.UpdateCartError(),
          ]),
        ),
      ),
    ),
  );

  createItemGroup$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.CreateItemGroup),
      map((action) => action.payload),
      withLatestFrom(this._store.select(CartSelectors.maxSortKeyOptional)),
      switchMap(([group, maxSortKeyOptional]) =>
        this._cartItemsService
          .createCartItemGroupAsync({
            ...group,
            sort_key_ui: maxSortKeyOptional + 1,
          })
          .pipe(
            mergeMap((data) => [
              CartActions.CreateItemGroupSuccess({
                payload: data,
              }),
            ]),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.cart.group_not_added',
                ),
              }),
              CartActions.CreateItemGroupError(),
            ]),
          ),
      ),
    ),
  );

  updateItemGroup$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.UpdateCartItemGroup),
      map((action) => action.payload),
      mergeMap((update) =>
        this._cartItemsService.updateCartItemGroupAsync(update).pipe(
          mergeMap(() => [
            CartActions.UpdateCartItemGroupSuccess({
              update,
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.group_not_updated',
              ),
            }),
            CartActions.UpdateCartItemGroupError(),
          ]),
        ),
      ),
    ),
  );

  deleteItemGroup$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.DeleteItemGroup),
      map((action) => action.payload),
      withLatestFrom(
        this._store
          .select(CartSelectors.id)
          .pipe(filter((cartId): cartId is number => cartId != null)),
      ),
      switchMap(([group]) =>
        this._cartItemsService.deleteCartItemGroupAsync(group.id).pipe(
          mergeMap(() => [
            CartActions.DeleteItemGroupSuccess({
              payload: group,
            }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.group_not_deleted',
              ),
            }),
            CartActions.DeleteItemGroupError(),
          ]),
        ),
      ),
    ),
  );

  stepperNaviagtion$ = createEffect(() =>
    this._actions.pipe(
      ofType<RouterNavigationAction>(ROUTER_NAVIGATED),
      map((action) => action.payload),
      map(
        (payload) =>
          payload.routerState['data'] && payload.routerState['data'].value,
      ),
      filter((payload): payload is StepperMapping => payload !== undefined),
      map((value) => CartActions.StepChanged({ payload: value })),
    ),
  );

  stepperIndex$ = createEffect(
    () =>
      this._actions.pipe(
        ofType(CartActions.SetStep),
        map((action) => action.payload),
        withLatestFrom(
          this._store.select(CartSelectors.selectedStep),
          this._store
            .select(ProjectSelectors.id)
            .pipe(filter((id): id is number => id != null)),
          this._store
            .select(CartSelectors.id)
            .pipe(filter((cartId): cartId is number => cartId != null)),
        ),
        tap(([payload, step, projectId, cartId]) => {
          if (payload !== step) {
            const stepPath = getStepPath(payload);
            const baseCommands = ['projects', projectId, 'carts', cartId];
            this._router.navigate(
              stepPath !== null
                ? [...baseCommands, 'configuration', stepPath]
                : baseCommands,
            );
          }
        }),
      ),
    { dispatch: false },
  );

  // checkout documents
  getCheckoutDocument$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.GetCheckoutDocuments),
      withLatestFrom(
        this._store
          .select(CartSelectors.id)
          .pipe(filter((id): id is number => id != null)),
      ),
      switchMap(([, cartId]) =>
        this._cartsService.getCartDocumentsAsync(cartId).pipe(
          map((data) =>
            CartActions.GetCheckoutDocumentsSuccess({
              documentContainer: data,
            }),
          ),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.cart.documents_not_loaded',
              ),
            }),
            CartActions.GetCheckoutDocumentsError(),
          ]),
        ),
      ),
    ),
  );

  createCartDocument$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.CreateCheckoutDocument),
      withLatestFrom(
        this._store
          .select(CartSelectors.id)
          .pipe(filter((id): id is number => id != null)),
      ),
      switchMap(([param, cartId]) =>
        this._cartsService
          .createCartDocumentAsync(
            cartId,
            param.isTuningCenter,
            param.isMcc,
            param.file,
          )
          .pipe(
            mergeMap((data) => [
              CartActions.CreateCheckoutDocumentSuccess({
                payload: data,
              }),
            ]),
            catchError((err) => [
              SnackbarActions.ShowError({
                error: err,
                message: this._translateService.instant(
                  'error_messages.documents.not_created',
                ),
              }),
              CartActions.CreateCheckoutDocumentError(),
            ]),
          ),
      ),
    ),
  );

  deleteCartDocument$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.DeleteCheckoutDocument),
      map((action) => action.docId),
      withLatestFrom(
        this._store
          .select(CartSelectors.id)
          .pipe(filter((id): id is number => id != null)),
      ),
      switchMap(([docId, cartId]) =>
        this._cartsService.deleteCartDocumentAsync(cartId, docId).pipe(
          mergeMap(() => [
            CartActions.DeleteCheckoutDocumentSuccess({ docId: docId }),
          ]),
          catchError((err) => [
            SnackbarActions.ShowError({
              error: err,
              message: this._translateService.instant(
                'error_messages.documents.not_deleted',
              ),
            }),
            CartActions.DeleteCheckoutDocumentError(),
          ]),
        ),
      ),
    ),
  );

  startServiceContractPolling$ = createEffect(() =>
    this._actions.pipe(
      ofType(CartActions.StartServiceContractPolling),
      map((action) => action.serviceContractId),
      withLatestFrom(this._store.select(CartSelectors.id)),
      switchMap(([serviceContractId, cartId]) =>
        // check cart every second for a whole minute
        interval(1000).pipe(
          take(60),
          concatMap(() => this._cartsService.getCartById(cartId || 0)),
          filter(
            (cart) =>
              cart?.contract_items?.some(
                (x) => x.service_contract?.contract_id === serviceContractId,
              ) || false,
          ),
          take(1),
          map((cart) => CartActions.LoadCartSuccess({ payload: cart })),
        ),
      ),
    ),
  );

  constructor(
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    private readonly _cartsService: CartsService,
    private readonly _cartItemsService: CartItemsService,
    private readonly _translateService: TranslateService,
  ) {}
}
