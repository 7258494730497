<tr
  *ngFor="let item of items$ | async"
  slProjectCartItemListRow
  [gaClickListener]="{
    category: 'Cart',
    action: 'ContractItemListClick',
    label: 'CartItemList',
  }"
  class="cdk-drag mat-row data-test-{{ item.name | slCssClass }}"
  [item]="item"
  [isReadOnly]="isReadOnly"
  [isPricingVisible]="isPricingVisible"
  [isItemWithCustomerPriceInput]="true"
  (edit)="onEdit($event)"
  (delete)="onDelete($event)"
  (toOptional)="onToOptional($event)"
  (quantityChange)="onQuantityChange($event)"
  cdkDrag
  cdkDragBoundary=".drag-drop-boundary"
  cdkDragLockAxis="y"
  [cdkDragDisabled]="isReadOnly"
  [ngClass]="{ 'dragable-row': !isReadOnly }"
></tr>
