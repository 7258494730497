<ng-container [matColumnDef]="perspective + 'FinalPrice'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right {{ perspective }}-total"
  >
    {{
      finalPrice
        | slMoneyAmount$
          : { display: 'symbol', ratio: 1, roundingDigits: MAX_ROUNDING_DIGITS }
        | async
    }}
  </td>
</ng-container>
