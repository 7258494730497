<ng-container *ngIf="control">
  <mat-form-field
    class="quantity"
    [style.width]="4 | dsSpacing"
    [gaClickListener]="{
      action: 'QuantityClick',
      category: 'Projects',
      label: 'CartItemList',
    }"
    data-test="quantity-input"
  >
    <input
      matInput
      type="number"
      [min]="1"
      [formControl]="control"
      autocomplete="off"
    />
  </mat-form-field>
</ng-container>
