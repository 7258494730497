<ng-container gaLabelGroup="CartCompare" gaCategoryGroup="Cart">
  <ng-container *ngIf="data">
    <h2 gaClickListener="TitleClick" mat-dialog-title data-test="title">
      {{ 'cart_compare.title' | translate }}
    </h2>
    <ds-text-indicator>
      {{ 'cart_compare.hint' | translate }}</ds-text-indicator
    >
    <mat-card class="duplication-details">
      <mat-card-content>
        <h4 data-test="duplication-details">
          {{ 'cart_compare.duplication_details' | translate }}
        </h4>
        @if (data.is_source_cart_deleted || data.is_source_project_deleted) {
          {{ 'cart_compare.duplicated_from' | translate }}
          {{
            data.is_source_cart_deleted
              ? ('cart_compare.original_deleted' | translate)
              : ('cart_compare.project_deleted' | translate)
          }}
          <br />
          {{ 'project.version' | translate }}:
          {{ data.source_cart_version }}
        } @else {
          <div>
            {{ 'cart_compare.duplicated_from' | translate }}
            <a
              [routerLink]="['projects', data.source_project_id]"
              mat-dialog-close
              data-test="duplicated-from"
              >{{ data.source_project_name }}</a
            >
          </div>
          <div>
            {{ 'project.version' | translate }}:
            <a
              [routerLink]="[
                'projects',
                data.source_project_id,
                'carts',
                data.source_cart_id,
              ]"
              mat-dialog-close
              data-test="project-version"
              >{{ data.source_cart_version }}</a
            >
          </div>
        }
      </mat-card-content>
    </mat-card>
  </ng-container>
  <mat-dialog-content>
    <ng-container *ngIf="!isLoading; else loading">
      <ng-container *ngIf="data; else error">
        <br />
        <sl-project-cart-compare-table
          *ngIf="data.items && data.items.length > 0; else noChanges"
          [items]="data.items"
          [sourceCurrency]="data.source_currency"
          [showTextChanges]="!!showTextChangeToggle?.checked"
          data-test="compare-table"
        ></sl-project-cart-compare-table>
        <ng-template #noChanges>
          <mat-card>
            <mat-card-content>
              <ds-placeholder
                [type]="'success'"
                [customHeadline]="
                  'cart_compare.placeholder_success_headline' | translate
                "
                data-test="empty-data-placeholder"
              >
                <div>
                  {{ 'cart_compare.placeholder_success_text' | translate }}
                </div>
              </ds-placeholder>
            </mat-card-content>
          </mat-card>
        </ng-template>
      </ng-container>
    </ng-container>
  </mat-dialog-content>

  <mat-dialog-actions fxLayoutAlign="space-between" fxLayout="row-reverse"
    ><div>
      <button
        mat-button
        mat-dialog-close
        gaClickListener="CloseClick"
        data-test="close-cart-compare"
      >
        {{ 'general.close' | translate }}
      </button>
    </div>
    <div *ngIf="data?.items && data.items.length > 0">
      <mat-slide-toggle
        [spotlight]="showTextSpotlight"
        gaClickListener="ShowTextChangesClick"
        data-test="show-text-changes-toggle"
      >
        {{ 'common.show_text_change' | translate }}
      </mat-slide-toggle>
      <ds-spotlight
        position="right"
        id="sl-project-cart-compare-show-text-changes"
        #showTextSpotlight
        [tags]="['2022.02.1', 'cart-compare']"
      >
        {{
          'spotlight.release_2022_01_02.copy_compare_show_text_changes'
            | translate
        }}
      </ds-spotlight>
    </div>

    <button
      mat-raised-button
      color="accent"
      (click)="onTryAgainClick()"
      gaClickListener="RetryClick"
      data-test="retry-button"
      *ngIf="!isLoading && !data"
    >
      {{ 'general.retry' | translate }}
    </button>
  </mat-dialog-actions>

  <ng-template #loading>
    <div class="loading-container">
      <ds-table-loading
        [numberOfHeaders]="7"
        [numberOfRows]="3"
        data-test="loading-table"
      ></ds-table-loading>
    </div>
  </ng-template>

  <ng-template #error>
    <ds-placeholder
      [type]="'error'"
      [customHeadline]="'cart_compare.placeholder_error_headline' | translate"
      hasError="true"
      data-test="error-placeholder"
      ><div>{{ 'cart_compare.placeholder_error_text' | translate }}</div>
    </ds-placeholder>
  </ng-template>
</ng-container>
