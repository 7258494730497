import { CartItemsService } from '@sales-libs/project/data-access';
import { catchError, map, tap } from 'rxjs';
import { SlProjectCustomArticleItemCreationRowFactory } from '../factories/custom-article-item-creation-row-factory';
import { SlProjectProductItemCreationRowFactory } from '../factories/product-item-creation-row-factory';
import { SlProjectSalesOptionItemCreationRowFactory } from '../factories/sales-option-item-creation-row-factory';
import { SlProjectWarrantyExtensionItemCreationRowFactory } from '../factories/warranty-extension-item-creation-row-factory';
import { SlProjectDataCreationOptionalGroup } from '../types/optional-group-type';
import { SlProjectCartItemCreationRow } from './cart-item-creation-row';
import { SlProjectDataCreationRowState } from './data-creation-row';

export class SlProjectCartItemGroupCreationRow extends SlProjectCartItemCreationRow {
  relatedCreationRows: SlProjectCartItemCreationRow[] = [];

  private _productItemCreationRowFactory: SlProjectProductItemCreationRowFactory;
  private _salesOptionItemCreationRowFactory: SlProjectSalesOptionItemCreationRowFactory;
  private _customArticleItemCreationRowFactory: SlProjectCustomArticleItemCreationRowFactory;
  private _warrantyExtensionItemCreationRowFactory: SlProjectWarrantyExtensionItemCreationRowFactory;

  constructor(
    private _group: SlProjectDataCreationOptionalGroup,
    private _cartItemsService: CartItemsService,
  ) {
    super(_group.name);

    this._productItemCreationRowFactory =
      new SlProjectProductItemCreationRowFactory(this._cartItemsService);
    this._salesOptionItemCreationRowFactory =
      new SlProjectSalesOptionItemCreationRowFactory(this._cartItemsService);
    this._customArticleItemCreationRowFactory =
      new SlProjectCustomArticleItemCreationRowFactory(this._cartItemsService);
    this._warrantyExtensionItemCreationRowFactory =
      new SlProjectWarrantyExtensionItemCreationRowFactory(
        this._cartItemsService,
      );

    this.relatedCreationRows = [
      ...(this._group.product_items
        ? this._productItemCreationRowFactory.create(this._group.product_items)
        : []),
      ...(this._group.warranty_extension_items?.map((item) =>
        this._warrantyExtensionItemCreationRowFactory.create(item),
      ) ?? []),
      ...(this._group.custom_article_items?.map((item) =>
        this._customArticleItemCreationRowFactory.create(item),
      ) ?? []),
      ...(this._group.sales_option_items?.map((item) =>
        this._salesOptionItemCreationRowFactory.create(item),
      ) ?? []),
    ];
  }

  protected _creationFunction = () =>
    this._cartItemsService
      .createCartItemGroupAsync({ ...this._group, cart_id: this.cartId })
      .pipe(
        tap((data) => {
          this.relatedCreationRows.forEach((row) => {
            row.cartId = this.cartId;
            row.cartItemGroupId = data.id;
            row.create();
          });
        }),
        map((data) => ({
          result: {
            data,
          },
          state: SlProjectDataCreationRowState.Success,
        })),
        catchError(() => [{ state: SlProjectDataCreationRowState.Error }]),
      );
}
