<ng-container
  [matColumnDef]="perspective + 'AverageDiscount'"
  gaLabelGroup="AverageDiscountColumn"
  gaCategoryGroup="Cart"
>
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right data-test-{{ perspective }}-average-discount"
    [gaClickListener]="perspective + 'AverageDiscountClick'"
  >
    {{ averageDiscount | percent: '1.2-2' : locale }}
  </td>
</ng-container>
