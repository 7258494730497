<ng-container [matColumnDef]="perspective + 'RelativeDiscount'">
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right data-test-{{ perspective }}-relative-discount-cell"
    [gaClickListener]="{
      action: perspective + 'RelativeDiscountClick',
      category: 'Cart',
      label: 'RelativeDiscountColumn',
    }"
  >
    {{
      formatNegativeValue(
        relativeDiscount
          | slMoneyAmount$
            : {
                display: 'symbol',
                ratio: 1,
                roundingDigits: MAX_ROUNDING_DIGITS,
              }
          | async
      )
    }}
  </td>
</ng-container>
