<ng-container
  [matColumnDef]="perspective + 'AverageDiscountExclNet'"
  gaLabelGroup="AverageDiscountExclNetColumn"
  gaCategoryGroup="Cart"
>
  <td
    mat-footer-cell
    *matFooterCellDef
    class="text-right data-test-{{ perspective }}-average-discount-excl-net"
    [gaClickListener]="perspective + 'AverageDiscountExclNetClick'"
  >
    {{ averageDiscountExclNet | percent: '1.2-2' : locale }}
  </td>
</ng-container>
