<ng-container
  gaCategoryGroup="LostOrderReporting"
  gaLabelGroup="LostOrderReportingOverview"
>
  <ds-page-sticky-header fxLayout="row" fxLayoutAlign="space-between baseline">
    <h1>
      {{ 'reporting.lost_order_reporting' | translate }}
    </h1>
    <mat-radio-group
      [(ngModel)]="showPercentage"
      [fxLayoutGap]="(1 | dsSpacing) + ' grid'"
      data-test="show-percentage-radio-group"
    >
      <mat-radio-button
        [value]="true"
        data-test="show-percentage-radio-button"
        >{{ 'reporting.show_percentage' | translate }}</mat-radio-button
      >
      <mat-radio-button
        [value]="false"
        [checked]="!showPercentage"
        data-test="show-value-radio-button"
        >{{ 'reporting.show_value' | translate }}</mat-radio-button
      >
    </mat-radio-group>
  </ds-page-sticky-header>
  <div fxLayout="column" [fxLayoutGap]="1 | dsSpacing" class="container">
    <sl-project-lost-order-reporting-filters
      (filter)="updateFilter($event)"
      data-test="filter"
    ></sl-project-lost-order-reporting-filters>
    @if ((totalLostOrders$ | async) === 0) {
      <div fxFlex="1 1 auto" fxLayout="column" fxLayoutAlign="center center">
        <ds-placeholder
          type="no_data"
          [customHeadline]="'reporting.no_data_for_filter' | translate"
          data-test="no-data-placeholder"
          gaClickListener="NoDataPlaceholderClick"
        >
        </ds-placeholder>
      </div>
    } @else {
      <div data-test="widgets">
        <div
          fxFlexLayout="column"
          [fxLayoutGap]="1.5 | dsSpacing"
          fxLayoutAlign="end center"
        ></div>
        <div fxLayout="row grid" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
          <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
            <sl-shared-reporting-single-value-widget
              [value]="totalLostOrders$ | async"
              [text]="'reporting.total_lost_orders' | translate"
              [color]="singleValueColors[0]"
              [isClickable]="false"
              gaClickListener="TotalOrdersClick"
              data-test="total-lost-order-widget"
            ></sl-shared-reporting-single-value-widget>
          </div>
          <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
            <sl-shared-reporting-single-value-widget
              *pdLet="mainCompetitor$ | async as mainCompetitor"
              [value]="mainCompetitor?.name"
              [text]="'reporting.main_competitor' | translate"
              [color]="singleValueColors[1]"
              (click)="
                updateFilterViaWidget({ competitors: [+mainCompetitor.id] })
              "
              gaClickListener="MainCompetitorClick"
              data-test="main-competitor-widget"
            ></sl-shared-reporting-single-value-widget>
          </div>
          <div fxFlex="100%" [fxFlex.gt-sm]="100 / 3 + '%'">
            <sl-shared-reporting-single-value-widget
              *pdLet="mainReason$ | async as mainReason"
              [value]="mainReason?.name"
              [text]="'reporting.main_reason' | translate"
              [color]="singleValueColors[2]"
              (click)="updateFilterViaWidget({ reasons: [+mainReason.id] })"
              gaClickListener="MainReasonClick"
              data-test="main-reason-widget"
            ></sl-shared-reporting-single-value-widget>
          </div>
        </div>
      </div>
      <div>
        <div fxLayout="row grid" [fxLayoutGap]="(1 | dsSpacing) + ' grid'">
          <div fxFlex="100%" fxFlex.gt-xs="100%" fxFlex.gt-md="66.666%">
            <sl-shared-reporting-line-chart
              [headline]="'reporting.lost_orders_over_time' | translate"
              [data]="dateDistributions$ | async"
              (select)="
                updateFilterViaWidget({
                  startDate: $event.from,
                  endDate: $event.to,
                })
              "
              gaClickListener="OverTimeBarChartClick"
              data-test="overtime-bar-chart-widget"
            ></sl-shared-reporting-line-chart>
          </div>
          <div fxFlex="100%" fxFlex.gt-sm="50%" fxFlex.gt-md="33.333%">
            <sl-shared-reporting-bar-chart
              [showPercentage]="showPercentage"
              [headline]="'reporting.competitors' | translate"
              [data]="competitorDistributions$ | async"
              (barClick)="updateFilterViaWidget({ competitors: [+$event] })"
              gaClickListener="CompetitorsBarChartClick"
              data-test="competitors-bar-chart-widget"
            ></sl-shared-reporting-bar-chart>
          </div>
          <div fxFlex="100%" fxFlex.gt-sm="50%" fxFlex.gt-md="33.333%">
            <sl-shared-reporting-bar-chart
              [showPercentage]="showPercentage"
              [headline]="'reporting.reasons' | translate"
              [data]="reasonDistributions$ | async"
              (barClick)="updateFilterViaWidget({ reasons: [+$event] })"
              gaClickListener="ReasonsBarChartClick"
              data-test="reasons-bar-chart-widget"
            ></sl-shared-reporting-bar-chart>
          </div>
          <div fxFlex="100%" fxFlex.gt-sm="50%" fxFlex.gt-md="33.333%">
            <sl-shared-reporting-bar-chart
              [showPercentage]="showPercentage"
              [headline]="'reporting.product_lines' | translate"
              [data]="productLineDistributions$ | async"
              (barClick)="
                updateFilterViaWidget({ productLines: ['' + $event] })
              "
              gaClickListener="ProductLinesBarChartClick"
              data-test="product-line-bar-chart-widget"
            ></sl-shared-reporting-bar-chart>
          </div>
          <div fxFlex="100%" fxFlex.gt-sm="50%" fxFlex.gt-md="33.333%">
            <sl-shared-reporting-bar-chart
              [showPercentage]="showPercentage"
              [headline]="'reporting.products' | translate"
              [data]="productDistributions$ | async"
              (barClick)="updateFilterViaWidget({ products: ['' + $event] })"
              gaClickListener="ProductLinesBarChartClick"
              data-test="product-bar-chart-widget"
            ></sl-shared-reporting-bar-chart>
          </div>
        </div>
      </div>
    }
  </div>
</ng-container>
