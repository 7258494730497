import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'slProjectReasonTranslation',
  standalone: true,
})
export class SlProjectReasonTranslationPipe implements PipeTransform {
  constructor(private _translateService: TranslateService) {}

  transform(reason: { id: number; name: string }): string {
    const key = 'edit_lost_order.reasons.' + reason.id;
    const translateValue = this._translateService.instant(key);

    if (translateValue === key) {
      return reason.name;
    }

    return translateValue;
  }
}
