import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { DsSpacingModule } from '@design-system/cdk/spacing';
import { DsFilterModule } from '@design-system/components/filter';
import { DsFilterInputModule } from '@design-system/components/filter-input';
import { DsPlaceholderModule } from '@design-system/components/placeholder';
import {
  DsTableLoadingModule,
  DsTableModule,
} from '@design-system/components/table';
import { DsTextIndicatorModule } from '@design-system/components/text-indicator';
import { DsPageModule } from '@design-system/feature/page';
import { DsSpotlightModule } from '@design-system/feature/spotlight';
import { TranslateModule } from '@ngx-translate/core';
import { SalestoolSharedModule } from '@sales-libs/shared/util';
import { GoogleAnalytics4DirectiveModule } from '@shared-lib/google-analytics';
import { SlProjectReasonTranslationPipe } from '../pipes';
import { SlProjectLostOrderFilterComponent } from './lost-order-filter/lost-order-filter.component';
import { SlProjectLostOrderListAmountColumnComponent } from './lost-order-list/lost-order-list-amount-column/lost-order-list-amount-column.component';
import { SlProjectLostOrderListCodeColumnComponent } from './lost-order-list/lost-order-list-code-column/lost-order-list-code-column.component';
import { SlProjectLostOrderListCommentColumnComponent } from './lost-order-list/lost-order-list-comment-column/lost-order-list-comment-column.component';
import { SlProjectLostOrderListCompetitorColumnComponent } from './lost-order-list/lost-order-list-competitor-column/lost-order-list-competitor-column.component';
import { SlProjectLostOrderListCompetitorPriceColumnComponent } from './lost-order-list/lost-order-list-competitor-price-column/lost-order-list-competitor-price-column.component';
import { SlProjectLostOrderListCompetitorTypeColumnComponent } from './lost-order-list/lost-order-list-competitor-type-column/lost-order-list-competitor-type-column.component';
import { SlProjectLostOrderListCreatedByColumnComponent } from './lost-order-list/lost-order-list-created-by-column/lost-order-list-created-by-column.component';
import { SlProjectLostOrderListCreatedDateColumnComponent } from './lost-order-list/lost-order-list-created-date-column/lost-order-list-created-date-column.component';
import { SlProjectLostOrderListCustomerColumnComponent } from './lost-order-list/lost-order-list-customer-column/lost-order-list-customer-column.component';
import { SlProjectLostOrderListDeliveryDateColumnComponent } from './lost-order-list/lost-order-list-delivery-date-column/lost-order-list-delivery-date-column.component';
import { SlProjectLostOrderListEndDateColumnComponent } from './lost-order-list/lost-order-list-end-date-column/lost-order-list-end-date-column.component';
import { SlProjectLostOrderListModelColumnComponent } from './lost-order-list/lost-order-list-model-column/lost-order-list-model-column.component';
import { SlProjectLostOrderListNameColumnComponent } from './lost-order-list/lost-order-list-name-column/lost-order-list-name-column.component';
import { SlProjectLostOrderListReasonColumnComponent } from './lost-order-list/lost-order-list-reason-column/lost-order-list-reason-column.component';
import { SlProjectLostOrderListComponent } from './lost-order-list/lost-order-list.component';
import { SlProjectLostOrderComponent } from './lost-order.component';

@NgModule({
  imports: [
    DsTableModule,
    DsTableLoadingModule,
    SalestoolSharedModule,
    DsPlaceholderModule,
    DsSpacingModule,
    DsSpotlightModule,
    DsPageModule,
    DsFilterModule,
    DsFilterInputModule,
    DsFilterModule,
    DsTextIndicatorModule,
    FlexLayoutModule,
    MatIconModule,
    TranslateModule,
    MatButtonModule,
    ReactiveFormsModule,
    MatTableModule,
    MatFormFieldModule,
    MatDatepickerModule,
    GoogleAnalytics4DirectiveModule,
    SlProjectReasonTranslationPipe,
  ],
  declarations: [
    SlProjectLostOrderComponent,
    SlProjectLostOrderListComponent,
    SlProjectLostOrderFilterComponent,
    SlProjectLostOrderListCodeColumnComponent,
    SlProjectLostOrderListCreatedByColumnComponent,
    SlProjectLostOrderListCreatedDateColumnComponent,
    SlProjectLostOrderListNameColumnComponent,
    SlProjectLostOrderListCustomerColumnComponent,
    SlProjectLostOrderListModelColumnComponent,
    SlProjectLostOrderListEndDateColumnComponent,
    SlProjectLostOrderListDeliveryDateColumnComponent,
    SlProjectLostOrderListAmountColumnComponent,
    SlProjectLostOrderListReasonColumnComponent,
    SlProjectLostOrderListCompetitorColumnComponent,
    SlProjectLostOrderListCompetitorTypeColumnComponent,
    SlProjectLostOrderListCompetitorPriceColumnComponent,
    SlProjectLostOrderListCommentColumnComponent,
  ],
})
export class SlProjectLostOrderModule {}
