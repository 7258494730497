<td
  class="min-width-cell mat-mdc-cell"
  [ngClass]="{
    'info-ribbon': editableItem.is_edited,
    'success-ribbon':
      !itemAsProductItem.is_optional && itemAsProductItem.is_valid === true,
    'error-ribbon':
      !itemAsProductItem.is_optional && itemAsProductItem.is_valid === false,
  }"
>
  <div fxLayout="row" fxLayoutAlign="start center">
    <sl-project-sort-column
      *ngIf="!isReadOnly && isSortingSupported"
    ></sl-project-sort-column>
    <sl-project-image-column [item]="cartItem"></sl-project-image-column>
  </div>
</td>
<td class="min-width-cell mat-mdc-cell">
  <sl-project-name-column [item]="cartItem"></sl-project-name-column>
</td>
<td *ngIf="!isTypeVisible" class="min-width-cell mat-mdc-cell">
  <sl-project-configuration-state-column
    [item]="cartItem"
  ></sl-project-configuration-state-column>
</td>
<td *ngIf="isTypeVisible" class="min-width-cell mat-mdc-cell">
  <sl-project-state-column [item]="cartItem"></sl-project-state-column>
</td>
<td class="mat-mdc-cell relative-position">
  <sl-project-description-column
    [item]="cartItem"
  ></sl-project-description-column>
</td>
<td *ngIf="isPricingVisible" class="min-width-cell mat-mdc-cell text-right">
  <sl-project-price-column
    [item]="cartItem"
    [isItemWithCustomerPriceInput]="isItemWithCustomerPriceInput"
  ></sl-project-price-column>
</td>
<td class="min-width-cell mat-mdc-cell">
  <sl-project-quantity-column
    [item]="cartItem"
    [isReadOnly]="isReadOnly || isSelectOnly"
    (quantityChange)="quantityChange.emit($event)"
  ></sl-project-quantity-column>
</td>
<td
  class="min-width-cell mat-mdc-cell mat-mdc-table-sticky"
  data-test="actions-column"
>
  <sl-project-actions-column
    *ngIf="!cartItem.is_optional"
    [item]="cartItem"
    [isReadOnly]="isReadOnly"
    [isSelectOnly]="isSelectOnly"
    (selectClick)="selectItem.emit($event)"
    (deleteClick)="delete.emit($event)"
    (configureClick)="configure.emit($event)"
    (toOptionalClick)="toOptional.emit($event)"
    (editClick)="edit.emit($event)"
    (showConfigurationClick)="showConfiguration.emit($event)"
  ></sl-project-actions-column>

  <sl-project-optional-actions-column
    *ngIf="cartItem.is_optional"
    [item]="cartItem"
    [isReadOnly]="isReadOnly"
    (deleteClick)="delete.emit($event)"
    (configureClick)="configure.emit($event)"
    (backToCartClick)="backToCart.emit($event)"
    (editTuningClick)="editTuning.emit($event)"
    (editMccClick)="editMcc.emit($event)"
    (editArticleClick)="editArticle.emit($event)"
    (priceDifferenceToClick)="priceDifferenceTo.emit($event)"
    (showConfigurationClick)="showConfiguration.emit($event)"
    (restoreOriginalPriceClick)="restoreOriginalPrice.emit($event)"
    (changeGroupClick)="changeGroup.emit($event)"
    (removeFromGroupClick)="removeFromGroup.emit($event)"
  >
  </sl-project-optional-actions-column>
</td>
<!--
  <td>
  <sl-project-optional-indicator-column
    [item]="cartItem"
  ></sl-project-optional-indicator-column>
</td>
-->
