import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DistributionsById,
  DistributionsByName,
} from '@sales-libs/project/data-access';
import { ProjectModuleState } from '../../sales-libs-project-feature.module';

const projectState = createFeatureSelector<ProjectModuleState>('projectModule');

export namespace LostOrderSelectors {
  const lostOrderState = createSelector(
    projectState,
    (state) => state.lostOrder,
  );

  export const lostOrderEntities = createSelector(
    lostOrderState,
    (state) => state.entities,
  );

  export const lostOrderId = createSelector(
    lostOrderState,
    (state) => state.id,
  );
  export const lostOrder = createSelector(
    lostOrderEntities,
    lostOrderId,
    (entities, id) => (id && entities[id]) || null,
  );

  export const lostOrderIds = createSelector(
    lostOrderState,
    (state) => state.ids as number[],
  );
  export const lostOrders = createSelector(
    lostOrderEntities,
    lostOrderIds,
    (entities, ids) =>
      ids && ids.length > 0 ? ids.map((id) => entities[id]) : null,
  );

  export const reasons = createSelector(
    lostOrderState,
    (state) => state.reasons,
  );
  export const competitors = createSelector(
    lostOrderState,
    (state) => state.competitors,
  );

  export const reportingFilter = createSelector(
    lostOrderState,
    (state) => state.lostOrderReportingFilters,
  );

  export const competitorDistributionsById = createSelector(
    lostOrderState,
    (state) => state.competitorDistributionsById,
  );

  export const reasonDistributionsById = createSelector(
    lostOrderState,
    (state) => state.reasonDistributionsById,
  );
  export const reasonDistributions = createSelector(
    reasons,
    reasonDistributionsById,
    (_reasons, distributions) =>
      _mapDistributionByIdToChartData(distributions, _reasons),
  );

  export const competitorDistributions = createSelector(
    competitors,
    competitorDistributionsById,
    (_competitors, distributions) =>
      _mapDistributionByIdToChartData(distributions, _competitors),
  );

  export const productLineDistributions = createSelector(
    lostOrderState,
    (state) =>
      _mapDistributionByNameToChartData(state.productLineDistributionsByName),
  );

  export const productDistributions = createSelector(lostOrderState, (state) =>
    _mapDistributionByNameToChartData(state.productDistributionsByName),
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  export const dateDistributions = createSelector(lostOrderState, (state) => {
    const data = state.dateDistributions;

    if (!data) return undefined;

    const startDate =
      state.lostOrderReportingFilters.startDate ??
      new Date(data.items[0].year ?? 0, (data.items[0].month ?? 0) - 1);
    const endDate =
      state.lostOrderReportingFilters.endDate ??
      new Date(
        data.items[data.items.length - 1].year ?? 0,
        (data.items[data.items.length - 1].month ?? 0) - 1,
      );

    const startYear = startDate.getFullYear();
    const endYear = endDate.getFullYear();
    const startYearFirstMonth = startDate.getMonth() + 1;
    const endYearLastMonth = endDate.getMonth() + 1;

    const yearDict: Record<number, Record<number, number>> = {};

    for (let year = startYear; year <= endYear; year++) {
      yearDict[year] = {};
      const startMonth = year === startYear ? startYearFirstMonth : 1;
      const endMonth = year === endYear ? endYearLastMonth : 12;
      for (let month = startMonth; month <= endMonth; month++) {
        yearDict[year][month] =
          data.items.find((item) => item.year === year && item.month === month)
            ?.count ?? 0;
      }
    }

    return yearDict;
  });

  const _mapDistributionByIdToChartData = (
    distributions: DistributionsById | undefined,
    referenceData: { name: string; id: number }[] | null | undefined,
  ) => {
    if (!distributions || !referenceData) return undefined;
    return distributions.items.map((distribution) => ({
      id: distribution.id?.toString() ?? '',
      name: referenceData.find((data) => data.id === distribution.id)?.name,
      value: distribution.count ?? 0,
    }));
  };

  const _mapDistributionByNameToChartData = (
    distributions: DistributionsByName | undefined,
  ) => {
    if (!distributions) return undefined;
    return distributions.items.map((item) => ({
      id: item.name ?? '',
      name: item.name,
      value: item.count,
    }));
  };
}
