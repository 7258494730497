<ng-container gaLabelGroup="DataCreationDialog" gaCategoryGroup="Project">
  <h3 matDialogTitle gaClickListener="TitleClick">
    {{
      (isProjectCreationIncluded
        ? 'project.project_creation_progress'
        : 'project.solution_creation_progress'
      ) | translate
    }}
  </h3>
  <mat-dialog-content>
    <table>
      <tr *ngFor="let row of rows">
        <td>{{ row.text }}</td>
        <td>
          <span
            *ngIf="row.state === rowStates.Loading"
            gaClickListener="InProgressStateClick"
          >
            {{ 'common.in_progress' | translate }}
          </span>
          <span
            *ngIf="row.state === rowStates.Success"
            class="success-text"
            gaClickListener="DoneStateClick"
          >
            {{ 'common.done' | translate }}
          </span>

          <span
            *ngIf="row.state === rowStates.Error"
            class="error-text"
            gaClickListener="FailedStateClick"
          >
            {{ 'common.failed' | translate }}
          </span>
          <div class="horizontal-spacing-helper">
            <span>{{ 'common.in_progress' | translate }}</span
            ><br />
            <span>{{ 'common.done' | translate }}</span
            ><br />
            <span>{{ 'common.failed' | translate }}</span
            ><br />
          </div>
        </td>
        <td>
          <div fxLayout fxLayoutAlign="end center">
            <mat-spinner
              *ngIf="row.state === rowStates.Loading"
              gaClickListener="LoadingSpinnerClick"
              color="accent"
              [diameter]="24"
            ></mat-spinner>
            <mat-icon
              *ngIf="row.state === rowStates.Success"
              class="success-text"
              gaClickListener="DoneIconClick"
              >check_circle</mat-icon
            >
            <button
              *ngIf="row.state === rowStates.Error"
              mat-stroked-button
              color="primary"
              gaClickListener="TryProjectCreationAgainClick"
              (click)="row.create()"
            >
              {{ 'common.try_again' | translate }}
            </button>
            <div class="vertical-spacing-helper">
              <button mat-stroked-button color="primary">
                {{ 'common.try_again' | translate }}
              </button>
            </div>
          </div>
          <div class="horizontal-spacing-helper">
            <button mat-stroked-button color="primary">
              {{ 'common.try_again' | translate }}
            </button>
          </div>
        </td>
      </tr>
    </table>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button
      mat-button
      type="button"
      mat-dialog-close
      gaClickListener="CancelClick"
    >
      {{ 'general.cancel' | translate }}
    </button>

    <button
      mat-raised-button
      type="submit"
      color="primary"
      (click)="onOpenClick()"
      [disabled]="!isCreationCompleted"
      gaClickListener="SaveClick"
      data-test="edit-project-save"
    >
      {{ 'common.open' | translate }}
    </button>
  </mat-dialog-actions>

  <ng-template #state let-isLoading let-isSuccessfull="isSuccessFull">
  </ng-template>

  <ng-template #stateIndicator let-isLoading let-isSuccessfull="isSuccessFull">
  </ng-template>
</ng-container>
