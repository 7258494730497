<ng-container [matColumnDef]="perspective + 'Margin'">
  <td
    mat-footer-cell
    *matFooterCellDef
    colspan="3"
    class="text-right"
    [gaClickListener]="{
      action: perspective + 'MarginClick',
      category: 'Cart',
      label: 'MarginColumn',
    }"
  >
    <span *ngIf="absoluteMargin !== undefined" data-test="absolute-margin">
      {{
        absoluteMargin
          | slMoneyAmount$
            : {
                display: 'symbol',
                ratio: 1,
                roundingDigits: MAX_ROUNDING_DIGITS,
              }
          | async
      }}
    </span>
    <span *ngIf="relativeMargin !== undefined" data-test="relative-margin">
      ({{ relativeMargin | percent: '1.2-2' : locale }})
    </span>
  </td>
</ng-container>
