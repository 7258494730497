import { createAction, props } from '@ngrx/store';
import {
  CompetitorsContainer,
  DistributionsByDate,
  DistributionsById,
  DistributionsByName,
  LostOrder,
  LostOrderUpdate,
  LostOrderViewContainer,
  ReasonsContainer,
} from '@sales-libs/project/data-access';
import { LostOrderReportingFilters } from '../../models';

export namespace LostOrderActions {
  // actions
  export const LoadLostOrder = createAction(
    '[LostOrder] LOAD_LOST_ORDER_BY_PROJECT',
    props<{ projectId: number }>(),
  );
  export const UpdateLostOrder = createAction(
    '[LostOrder] UPDATE_LOST_ORDER',
    props<{ payload: LostOrderUpdate }>(),
  );
  export const AddLostOrder = createAction(
    '[LostOrder] ADD_LOST_ORDER',
    props<{ payload: LostOrder }>(),
  );
  export const DeleteLostOrder = createAction(
    '[LostOrder] DELETE_LOST_ORDERS',
    props<{ projectId: number }>(),
  );

  export const LoadReasons = createAction('[LostOrder] LOAD_REASONS');
  export const LoadCompetitors = createAction('[LostOrder] LOAD_COMPETITORS');

  // results actions
  export const LoadLostOrderSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_SUCCESS',
    props<{ payload: LostOrder }>(),
  );
  export const UpdateLostOrderSuccess = createAction(
    '[LostOrder] UPDATE_LOST_ORDER_SUCCESS',
    props<{ payload: LostOrder }>(),
  );
  export const AddLostOrderSuccess = createAction(
    '[LostOrder] ADD_LOST_ORDER_SUCCESS',
    props<{ payload: LostOrder }>(),
  );
  export const DeleteLostOrderSuccess = createAction(
    '[LostOrder] DELETE_LOST_ORDER_SUCCESS',
    props<{ payload: number }>(),
  );

  export const LoadReasonsSuccess = createAction(
    '[LostOrder] LOAD_REASONS_SUCCESS',
    props<{ payload: ReasonsContainer }>(),
  );
  export const LoadCompetitorsSuccess = createAction(
    '[LostOrder] LOAD_COMPETITORS_SUCCESS',
    props<{ payload: CompetitorsContainer }>(),
  );

  export const LostOrderReportingFilterChange = createAction(
    '[LostOrder] LOST_ORDER_REPORTING_FILTER_CHANGE',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadLostOrderReportingSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_SUCCESS',
    props<{ payload: LostOrderViewContainer }>(),
  );

  export const LoadCompetitorDistributions = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_COMPETITOR_DISTRIBUTIONS',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadCompetitorDistributionsSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_COMPETITOR_DISTRIBUTIONS_SUCCESS',
    props<{ payload: DistributionsById }>(),
  );
  export const LoadCompetitorDistributionsError = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_COMPETITOR_DISTRIBUTIONS_ERROR',
  );

  export const LoadReasonDistributions = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_REASON_DISTRIBUTIONS',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadReasonDistributionsSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_REASON_DISTRIBUTIONS_SUCCESS',
    props<{ payload: DistributionsById }>(),
  );
  export const LoadReasonDistributionsError = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_REASON_DISTRIBUTIONS_ERROR',
  );

  export const LoadProductLineDistributions = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT_LINE_DISTRIBUTIONS',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadProductLineDistributionsSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT_LINE_DISTRIBUTIONS_SUCCESS',
    props<{ payload: DistributionsByName }>(),
  );
  export const LoadProductLineDistributionsError = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT_LINE_DISTRIBUTIONS_ERROR',
  );

  // date distributions
  export const LoadDateDistributions = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_DATE_DISTRIBUTIONS',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadDateDistributionsSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_DATE_DISTRIBUTIONS_SUCCESS',
    props<{ payload: DistributionsByDate }>(),
  );
  export const LoadDateDistributionsError = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_DATE_DISTRIBUTIONS_ERROR',
  );

  export const LoadProductDistributions = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT',
    props<{ payload: LostOrderReportingFilters }>(),
  );
  export const LoadProductDistributionsSuccess = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT_SUCCESS',
    props<{ payload: DistributionsByName }>(),
  );
  export const LoadProductDistributionsError = createAction(
    '[LostOrder] LOAD_LOST_ORDER_REPORTING_PRODUCT_ERROR',
  );

  export const Error = createAction('[LostOrder] ERROR'); //here should be a payload, but i removed it, because i think there is nothing in it
}
