import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import {
  ContractItem,
  CustomArticleItem,
  MccItem,
  SalesOptionCartItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import { MAX_ROUNDING_DIGITS, SlCartItem } from '@sales-libs/shared/util';

type CustomPriceItem =
  | ContractItem
  | CustomArticleItem
  | MccItem
  | SalesOptionCartItem
  | TuningCenterItem
  | WarrantyExtensionItem;

@Component({
  selector: 'sl-project-price-column',
  templateUrl: './price-column.component.html',
  styleUrls: ['./price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectPriceColumnComponent {
  @Input() isItemWithCustomerPriceInput = false;
  @Input() set item(value: SlCartItem) {
    this.cartItem = value;
    this.itemAsCustomPriceItem = value as CustomPriceItem;
  }

  MAX_ROUNDING_DIGITS = MAX_ROUNDING_DIGITS;
  cartItem: SlCartItem;
  itemAsCustomPriceItem: CustomPriceItem;
}
