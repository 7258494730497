<ds-filter
  [formGroup]="filtersForm"
  (resetFilter)="filtersForm.reset()"
  gaLabelGroup="LostOrderReporting"
  gaCategoryGroup="LostOrderReportingFilter"
>
  <!-- RangeDate -->
  <mat-form-field
    gaClickListener="LostOrderReportingDateRangeFilterOpenClick"
    *dsFilterItem="filtersForm.controls.startDate"
  >
    <mat-label>{{ 'general.date_range' | translate }}</mat-label>
    <mat-date-range-input
      [formGroup]="filtersForm"
      [rangePicker]="lostOrderReportingRangepicker"
      data-test="date-range-input"
    >
      <input
        matStartDate
        [formControl]="filtersForm.controls.startDate"
        [placeholder]="'common.start_date' | translate"
      />
      <input
        matEndDate
        [formControl]="filtersForm.controls.endDate"
        [placeholder]="'common.end_date' | translate"
      />
    </mat-date-range-input>
    <mat-datepicker-toggle
      matSuffix
      [for]="lostOrderReportingRangepicker"
    ></mat-datepicker-toggle>
    <mat-date-range-picker
      [calendarHeaderComponent]="dsPresetCalenderHeaderComponent"
      #lostOrderReportingRangepicker
      gaClickListener="LostOrderReportingDatePickerClick"
    ></mat-date-range-picker>
  </mat-form-field>

  <!-- Competitor -->
  <mat-form-field
    *dsFilterItem="filtersForm.controls.competitors; sortKey: 1"
    data-test="competitor-filter"
  >
    <mat-label fxLayout>
      <span>{{ 'table_columns.competitor' | translate }}</span>
      <ng-container *ngIf="isCompetitorsLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
    </mat-label>
    <mat-select
      multiple
      [formControl]="filtersForm.controls.competitors"
      data-test="competitor-select"
    >
      <mat-option
        *ngFor="let competitor of competitors"
        [value]="competitor.id"
        >{{ competitor?.name }}</mat-option
      >
    </mat-select>
    <mat-hint
      *ngIf="!isCompetitorsLoading && filtersForm.controls.competitors.disabled"
      data-test="loading-error-hint"
      (click)="getCompetitors()"
    >
      <ng-container *ngTemplateOutlet="errorHint"></ng-container>
    </mat-hint>
  </mat-form-field>

  <!-- Product Line  -->
  <mat-form-field
    *dsFilterItem="filtersForm.controls.productLines; sortKey: 2"
    data-test="product-line-filter"
  >
    <mat-label fxLayout>
      <span>{{ 'project_detail.product_line' | translate }}</span>
      <ng-container *ngIf="isProductLinesLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
    </mat-label>
    <mat-select
      [formControl]="filtersForm.controls.productLines"
      multiple
      data-test="product-line-select"
    >
      <mat-option
        *ngFor="let productLine of productLines"
        [value]="productLine"
      >
        <div fxLayout [fxLayoutGap]="0.25 | dsSpacing">
          <sl-shared-product-line-icon [productLine]="productLine">
          </sl-shared-product-line-icon>
          <span>
            {{ 'productline.' + productLine + '.name' | translate }}
          </span>
        </div>
      </mat-option>
    </mat-select>
    <mat-hint
      *ngIf="
        !isProductLinesLoading && filtersForm.controls.productLines.disabled
      "
      class="loading-error-hint"
      data-test="loading-error-hint"
      (click)="getProductLines()"
    >
      <ng-container *ngTemplateOutlet="errorHint"></ng-container>
    </mat-hint>
  </mat-form-field>

  <!-- Products  -->
  <ds-filter-input
    [data]="products"
    [control]="filtersForm.controls.products"
    [label]="'common.products' | translate"
    [isSingleSelectionDisabled]="true"
    [isLoading]="isProductsLoading"
    data-test="products-filter"
    *dsFilterItem="filtersForm.controls.products; sortKey: 3"
  >
    <mat-hint
      *ngIf="!isProductsLoading && filtersForm.controls.products.disabled"
      class="loading-error-hint"
      data-test="loading-error-hint"
      (click)="getProducts()"
    >
      <ng-container *ngTemplateOutlet="errorHint"></ng-container>
    </mat-hint>
  </ds-filter-input>

  <!-- Reasons  -->
  <mat-form-field
    *dsFilterItem="filtersForm.controls.reasons; sortKey: 4"
    data-test="reasons-filter"
  >
    <mat-label fxLayout>
      <span>{{ 'edit_lost_order.cause_of_loss' | translate }}</span>

      <ng-container *ngIf="isReasonsLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container>
      </ng-container>
    </mat-label>

    <mat-select
      [formControl]="filtersForm.controls.reasons"
      multiple
      data-test="reasons-select"
    >
      <mat-option *ngFor="let reason of reasons" [value]="reason.id">{{
        { id: reason.id, name: reason.name } | slProjectReasonTranslation
      }}</mat-option>
    </mat-select>
    <mat-hint
      *ngIf="!isReasonsLoading && filtersForm.controls.reasons.disabled"
      class="loading-error-hint"
      data-test="loading-error-hint"
      (click)="getReasons()"
    >
      <ng-container *ngTemplateOutlet="errorHint"></ng-container>
    </mat-hint>
  </mat-form-field>

  <!-- Created By  -->
  <mat-form-field
    *dsFilterItem="filtersForm.controls.createdBy; sortKey: 5"
    data-test="created-by-filter"
  >
    <mat-label fxLayout>
      <span>{{ 'project.created_by' | translate }}</span>

      <ng-container *ngIf="isCreatedByLoading">
        <ng-container *ngTemplateOutlet="loading"></ng-container> </ng-container
    ></mat-label>
    <mat-select
      [formControl]="filtersForm.controls.createdBy"
      multiple
      data-test="created-by-select"
    >
      <mat-option *ngFor="let creator of createdBy" [value]="creator">{{
        creator
      }}</mat-option>
    </mat-select>
    <mat-hint
      *ngIf="!isCreatedByLoading && filtersForm.controls.createdBy.disabled"
      class="loading-error-hint"
      data-test="loading-error-hint"
      (click)="getCreatedBy()"
    >
      <ng-container *ngTemplateOutlet="errorHint"></ng-container>
    </mat-hint>
  </mat-form-field>
</ds-filter>

<ng-template #loading>
  <span>&nbsp;</span>
  <mat-progress-spinner
    [mode]="'indeterminate'"
    color="accent"
    [diameter]="18"
    data-test="loading-spinner"
  >
  </mat-progress-spinner>
</ng-template>

<ng-template #errorHint>
  {{ 'common.filter_not_loaded' | translate }}
  <a>{{ 'common.try_again' | translate }}</a>
</ng-template>
