import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  Input,
} from '@angular/core';
import {
  Cart,
  ContractItem,
  CustomArticleItem,
  MccItem,
  ProductItem,
  ProductItemProductType,
  SolutionItem,
  TuningCenterItem,
  WarrantyExtensionItem,
} from '@sales-libs/project/data-access';
import {
  ContractName,
  FEATURE_FLAG_SERVICE_CONTRACTS,
  MAX_ROUNDING_DIGITS,
  SlSharedMathUtils,
} from '@sales-libs/shared/util';
import { WarrantyExtensionUtils } from '../../utils/warranty-extension.utils';

@Component({
  selector: 'sl-project-product-preview-prices',
  templateUrl: './product-preview-prices.component.html',
  styleUrls: ['./product-preview-prices.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SlProjectProductPreviewPricesComponent {
  @Input() set cart(value: Cart) {
    this._cart = value;
    this.craneCartItem = value.product_items?.find(
      (item) => item.product_type === ProductItemProductType.Product,
    );

    this.solutionCartItem = value.solution_items?.filter(
      (x) => !x.is_optional,
    )[0];

    this.equipmentItems = value.product_items?.filter(
      (x) =>
        x.product_type === ProductItemProductType.Accessory && !x.is_optional,
    );
    this.equipmentsTotalPrice =
      this.equipmentItems?.reduce(
        (acc, x) =>
          acc +
          +SlSharedMathUtils.roundAwayFromZero(
            x.sales_price * value.currency_settings.ratio,
            value.currency_settings.rounding_digits,
          ).toFixed(2) *
            (x.quantity === 0 ? 1 : x.quantity),
        0,
      ) || 0;

    this.customArticleItems = value.custom_article_items?.filter(
      (x) => !x.is_optional,
    );
    this.customArticlesTotalPrice =
      this.customArticleItems?.reduce(
        (acc, x) => acc + x.sales_price * x.quantity,
        0,
      ) || 0;

    this.contractItems = value.contract_items?.filter((x) => !x.is_optional);
    this.contractsTotalPrice =
      this.contractItems?.reduce(
        (acc, x) => acc + x.sales_price * x.quantity,
        0,
      ) || 0;

    this.tuningCenterItem = value.tuning_center_items?.filter(
      (x) => !x.is_optional,
    )[0];
    this.mccItem = value.mcc_items?.filter((x) => !x.is_optional)[0];

    const warrantyItemsWithPrices = WarrantyExtensionUtils.getItemsWithPrices(
      value.warranty_extension_items?.filter((x) => !x.is_optional),
      this.craneCartItem,
      value.currency_settings,
    );
    this.warrantyExtensionItem =
      warrantyItemsWithPrices && warrantyItemsWithPrices[0];

    this.craneCartItemTotalPrice =
      (this.craneCartItem?.sales_price || 0) +
      (this.tuningCenterItem?.sales_price || 0) +
      (this.mccItem?.sales_price || 0) +
      (this.warrantyExtensionItem?.sales_price || 0);
  }

  get cart() {
    return this._cart;
  }

  MAX_ROUNDING_DIGITS = MAX_ROUNDING_DIGITS;
  contractNames = ContractName;

  private _cart: Cart;

  constructor(
    @Inject(FEATURE_FLAG_SERVICE_CONTRACTS)
    public isServiceContractFeatureFlagSet,
  ) {}

  craneCartItemTotalPrice: number;
  craneCartItem: ProductItem | null | undefined;
  solutionCartItem: SolutionItem | null | undefined;
  equipmentItems: ProductItem[] | null | undefined;
  equipmentsTotalPrice: number;
  customArticleItems: CustomArticleItem[] | null | undefined;
  customArticlesTotalPrice: number;
  contractItems: ContractItem[] | null | undefined;
  contractsTotalPrice: number;
  warrantyExtensionItem: WarrantyExtensionItem | null | undefined;
  tuningCenterItem: TuningCenterItem | null | undefined;
  mccItem: MccItem | null | undefined;
}
