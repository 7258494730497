import { formatDate } from '@angular/common';
import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { dsConfig } from '@design-system/cdk/config';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Competitor, Reason } from '@sales-libs/project/data-access';
import { ChartData, LineChartData } from '@sales-libs/shared/ui';
import { filter, map, Observable, take, tap } from 'rxjs';
import { LostOrderActions, LostOrderSelectors } from '../../lost-order/store';
import { LostOrderReportingFilters } from '../../models';

const translationNotSetKey = 'reporting.not_set';

@Component({
  selector: 'sl-project-lost-order-reporting-overview',
  templateUrl: './lost-order-reporting-overview.component.html',
  styleUrls: ['./lost-order-reporting-overview.component.scss'],
  standalone: false,
})
export class SlProjectLostOrderReportingOverviewComponent implements OnInit {
  totalLostOrders$: Observable<number | undefined>;
  mainCompetitor$: Observable<ChartData | undefined>;
  mainReason$: Observable<ChartData | undefined>;

  competitors: Competitor[];
  reasons: Reason[];
  showPercentage = false;

  singleValueColors: string[] = [
    dsConfig.colors.primary[600],
    dsConfig.colors.primary[400],
    dsConfig.colors.primary[300],
  ];

  competitors$: Observable<Competitor[]>;
  reasons$: Observable<Reason[]>;
  competitorDistributions$: Observable<ChartData[] | undefined>;
  reasonDistributions$: Observable<ChartData[] | undefined>;
  productLineDistributions$: Observable<ChartData[] | undefined>;
  dateDistributions$: Observable<LineChartData | undefined>;
  productDistributions$: Observable<ChartData[] | undefined>;

  constructor(
    private readonly _store: Store,
    private readonly _translate: TranslateService,
    @Inject(LOCALE_ID) private _locale: string,
  ) {
    this.competitors$ = this._store.select(LostOrderSelectors.competitors).pipe(
      map((data) => data ?? []),
      tap((data) => (this.competitors = data)),
    );
    this.reasons$ = this._store.select(LostOrderSelectors.reasons).pipe(
      filter((data): data is Reason[] => !!data),
      tap((data) => (this.reasons = data)),
    );

    this.reasonDistributions$ = this._store
      .select(LostOrderSelectors.reasonDistributions)
      .pipe(map((data) => this._setReasonName(data)));
    this.competitorDistributions$ = this._store
      .select(LostOrderSelectors.competitorDistributions)
      .pipe(map((data) => this._setDefaultName(data)));

    this.totalLostOrders$ = this.reasonDistributions$.pipe(
      map((data) => data?.reduce((acc, item) => acc + item.value, 0)),
    );
    this.mainCompetitor$ = this.competitorDistributions$.pipe(
      map((data) => data?.sort((a, b) => b.value - a.value) && data?.[0]),
    );
    this.mainReason$ = this.reasonDistributions$.pipe(
      map((data) => data?.sort((a, b) => b.value - a.value) && data?.[0]),
    );
    this.productLineDistributions$ = this._store
      .select(LostOrderSelectors.productLineDistributions)
      .pipe(map((data) => this._setProductLineName(data)));

    this.dateDistributions$ = this._store
      .select(LostOrderSelectors.dateDistributions)
      .pipe(map((data) => this._mapToLineChartData(data)));

    this.productDistributions$ = this._store
      .select(LostOrderSelectors.productDistributions)
      .pipe(map((data) => this._setDefaultName(data)));
  }

  ngOnInit() {
    this._store.dispatch(LostOrderActions.LoadCompetitors());
    this._store.dispatch(LostOrderActions.LoadReasons());
    this._store
      .select(LostOrderSelectors.reportingFilter)
      .pipe(take(1))
      .subscribe((reportingFilter) => {
        this._store.dispatch(
          LostOrderActions.LostOrderReportingFilterChange({
            payload: reportingFilter,
          }),
        );
      });
  }

  /**
   * dispatch filter change and take first page
   * @param filters
   */
  updateFilter(filters: LostOrderReportingFilters) {
    this._store.dispatch(
      LostOrderActions.LostOrderReportingFilterChange({ payload: filters }),
    );
  }

  updateFilterViaWidget(filters: LostOrderReportingFilters) {
    this._store
      .select(LostOrderSelectors.reportingFilter)
      .pipe(take(1))
      .subscribe((currentFilter) => {
        Object.keys(filters).forEach((key) => {
          // unset filter if clicked again
          if (key === 'startDate' || key === 'endDate') {
            if (
              filters[key]?.toUTCString().substring(0, 10) ===
              currentFilter[key]?.toUTCString().substring(0, 10)
            ) {
              filters = { ...filters, [key]: undefined };
            }
          } else if (
            // '0' is 'Not Set' and can not be filtered
            filters[key]?.join('') === '0' ||
            filters[key]?.sort().join('') ===
              currentFilter[key]?.sort().join('')
          ) {
            filters = { ...filters, [key]: undefined };
          }
        });
        this._store.dispatch(
          LostOrderActions.LostOrderReportingFilterChange({
            payload: {
              ...currentFilter,
              ...filters,
            },
          }),
        );
      });
  }

  private _setProductLineName(
    data:
      | {
          id: string;
          name: string | null | undefined;
          value: number;
        }[]
      | undefined,
  ): ChartData[] | undefined {
    return data?.map((item) => ({
      ...item,
      name: item.name
        ? this._translate.instant('productline.' + item.name + '.name')
        : this._translate.instant(translationNotSetKey),
    }));
  }

  private _setReasonName(
    data:
      | {
          id: string;
          name: string | null | undefined;
          value: number;
        }[]
      | undefined,
  ): ChartData[] | undefined {
    return data?.map((item) => ({
      ...item,
      name: item.name
        ? this._translate.instant('edit_lost_order.reasons.' + item.id)
        : this._translate.instant(translationNotSetKey),
    }));
  }

  private _setDefaultName(
    data:
      | { id: string; name: string | null | undefined; value: number }[]
      | undefined,
  ): ChartData[] | undefined {
    return data?.map((item) => ({
      ...item,
      name: item.name ?? this._translate.instant(translationNotSetKey),
    }));
  }

  private _mapToLineChartData(
    yearDict: Record<number, Record<number, number>> | undefined,
  ): LineChartData | undefined {
    if (!yearDict) {
      return undefined;
    }

    const result: LineChartData = {
      name: this._translate.instant('reporting.total_lost_orders'),
      series: [],
    };
    // if there are more than one year and at least one year with all months filled, show year sums
    const showYearSums =
      Object.keys(yearDict).length > 1 &&
      Object.values(yearDict).find((year) => Object.keys(year).length === 12);

    Object.keys(yearDict).forEach((year) => {
      if (showYearSums) {
        result.series.push({
          id: year,
          name: year,
          value: Object.values(yearDict[+year]).reduce(
            (acc: number, item: number) => acc + item,
            0,
          ),
          from: new Date(+year, 0, 1),
          to: new Date(+year, 11, 31),
        });
      } else {
        Object.keys(yearDict[year]).forEach((month) => {
          // show months only if all data is in the same year
          const dateFormatting =
            Object.keys(yearDict).length === 1 ? 'MMM' : 'MMM yyyy';
          result.series.push({
            id: `${year}-${month}`,
            name: formatDate(
              `${year}-${month}-01`,
              dateFormatting,
              this._locale,
            ),
            value: yearDict[year][month],
            from: new Date(+year, +month - 1, 1),
            to: new Date(+year, +month, 0),
          });
        });
      }
    });

    return result;
  }
}
